<template>
  <div class="home">
<!--    <elMenu></elMenu>-->
    <img src="../../../public/images/brief/head.jpg" width="100%">
    <div class="content1_2">
      <div>
        <img src="../../../public/images/brief/2.jpg" width="495px" height="330px">
      </div>
      <div class="content1_2_right">
        <div>
          深圳市维拍物联智能技术有限公司是一个全球化的智能物联网云平台。始创于2015年，隶属深圳市威视达康科技有限公司。成立之初便致力于通过“IoT+云平台”，一站式方案解决企业视频产品的智能化升级。
          维拍公司专注于以视频技术为核心，涉足AI、物联网等方向，其中包含人脸识别、人脸追踪、人形识别、图像识别及智能模块互联等技术，拥有数十项国家高新专利，是国内领先的智能视频产品方案提供商。公司研发团队高达百余人，资深、高效、专业。
        </div>
        <div class="content1_2_right_top">
          VeePai云平台是维拍公司旗下集云存储、云控制等多功能智慧平台。平台覆盖全球190+国家地区，180+云产品服务，超200w+开发者，服务3000+企业。为企业提供安全稳定的云平台服务，支持多语种模式，加快企业全球化进程。
        </div>
      </div>
    </div>
<!--    top-->
    <div class="content1_2">
      <div class="content1_2_right">
        <div>
          为客户创造价值。维拍，在全球进行云覆盖布局，实现全球亿万人互联。维拍为企业提供开放、灵活、安全的云平台基础设施，推动企业数字化、智能化转型；为云服务客户提供稳定可靠、安全可信和持续创新的云服务。
          维拍的VeePai云平台和智能视频产品，正在帮助企业高效的完成智能化、数字化转型工作。
        </div>
        <div class="content1_2_right_top">
          VeePai云将发挥软硬件协同优势，全面提升用户体验、云端服务和安全能力，持续为企业客户打造全球最佳体验的云服务，同时不断更新智能视频产品终端，为企业提供智能视频产品升级方案闭环。
        </div>
      </div>
      <div>
        <img src="../../../public/images/brief/1.jpg" width="495px" height="330px">
      </div>
    </div>
    <div class="character">
      维拍一直秉承着“客户至上、使命必达”的核心理念。产品不断创新，服务不断优化，技术不断升级，多维度上满足企业数字化、智能化升级所需。竭力为合作伙伴深耕市场，进行全球化布局。
    </div>
    <foot></foot>
    <navigation></navigation>
  </div>
</template>

<script>
import elMenu from '../../../src/components/elMenu'
import foot from '../../../src/components/foot'
import navigation from '../../../src/components/navigation'
export default {
  name: "companyProfile",
  components: {
    elMenu,foot,navigation
  },
}
</script>

<style scoped>

.content1_2 {
  padding-top: 80px;
  display: flex;
  width: 1360px;
  margin: 0px auto;
  height: 400px;
}

.content1_2_right {
  text-indent: 2em;
  margin: 10px;
  line-height: 30px;
  font-size: 18px;
}

.content1_2_right_top {
  margin-top: 70px;
}
.character{
  width:80%;
 margin: 0px auto;
  height:220px;
  font-size: 24px;
  text-indent: 2em;
}
</style>